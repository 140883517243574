/*
 *
 * TYPOGRAPHY VARIABLES AND MAPS
 *
 */

@font-face {
	font-family: 'Gotham';
	src: url('/assets/fonts/gotham/Gotham-XLight.otf') format('opentype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('/assets/fonts/gotham/Gotham-XLightItalic.otf') format('opentype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham';
	src: url('/assets/fonts/gotham/Gotham-Light.otf') format('opentype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('/assets/fonts/gotham/Gotham-LightItalic.otf') format('opentype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham';
	src: url('/assets/fonts/gotham/Gotham-Book.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('/assets/fonts/gotham/Gotham-BookItalic.otf') format('opentype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham';
	src: url('/assets/fonts/gotham/Gotham-Medium.otf') format('opentype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('/assets/fonts/gotham/Gotham-MediumItalic.otf') format('opentype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham';
	src: url('/assets/fonts/gotham/Gotham-Bold.otf') format('opentype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('/assets/fonts/gotham/Gotham-BoldItalic.otf') format('opentype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham';
	src: url('/assets/fonts/gotham/Gotham-Black.otf') format('opentype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('/assets/fonts/gotham/Gotham-BlackItalic.otf') format('opentype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham';
	src: url('/assets/fonts/gotham/Gotham-Ultra.otf') format('opentype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham';
	src: url('/assets/fonts/gotham/Gotham-UltraItalic.otf') format('opentype');
	font-weight: 900;
	font-style: italic;
}

$font-family-primary: 'Gotham', sans-serif;
$font-family-sans-serif: 'Open Sans', sans-serif;
$font-family-serif: 'Noto Serif', serif;
$font-family-noto-sans-serif: 'Noto Sans', sans-serif;
$font-family-lato: 'Lato', sans-serif;

$font-weight-xlight: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;
