.featured-providers-input {
	margin-bottom: 1rem;
	position: relative;

	+ .inner-container {
		.featured-providers-result {
			margin-top: -1rem;
		}
	}

	svg {
		position: absolute;
		height: 25px;
		width: 25px;
		left: 0.5rem;
		top: 50%;
		transform: translateY(-50%);
		fill: $color-black;
		z-index: 2;
	}

	label {
		@include text(12, 18);
		color: $color-neutral-darker;
		position: absolute;
		left: 2.25rem;
		top: 10px;
		z-index: 2;
	}

	input {
		@include text(18, 24);
		background-color: $color-neutral-lightest-2;
		border: none;
		border-radius: 0;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		color: $color-black;
		display: flex;
		height: 60px;
		margin: 0;
		padding: 1.5rem 1.25rem 5px 2.25rem;
		width: 100%;

		&::placeholder {
			color: $color-neutral;
		}
	}

	.react-autosuggest__container {
		position: relative;
		z-index: 1;
	}

	.react-autosuggest__input {
		&--open,
		&:focus {
			+ .react-autosuggest__suggestions-container {
				display: block;
			}
		}
	}

	.location-search-input {
		&:focus {
			+ .react-autosuggest__suggestions-container {
				display: block;
			}
		}
	}

	.react-autosuggest__suggestions-container {
		background-color: $color-white;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		box-shadow: 0px -5px 23px 4px rgba($color-black, 0.15);
		display: none;
		margin-top: -20px;
		padding: 30px 10px 0;
		position: absolute;
		top: 100%;
		width: 100%;
		z-index: -1;

		[aria-selected='true'] {
			.typeahead-suggestion {
				background-color: $color-neutral-lightest-2;
				color: $color-neutral-darker;
			}
		}

		.filter-title {
			color: $color-neutral-dark;
		}
	}

	.typeahead-suggestion {
		@include text(14, 20);
		background-color: rgba($color-white, 0.5);
		border-radius: 10px;
		color: $color-neutral-darker;
		display: flex;
		justify-content: space-between;
		margin: 0 0 0.75rem;
		padding: 0 0 0 0.5rem;
		padding: 0.625rem 1.25rem;
		width: 100%;

		&:hover,
		&:focus,
		&[aria-selected='true'] {
			background-color: $color-neutral-lightest-2;
			cursor: pointer;
		}

		mark {
			background-color: $color-green-lighter;
		}

		.suggestion-name {
			flex-grow: 1;
		}

		.suggestion-number {
			padding-left: 1rem;
		}
	}

	@container (min-width: 250px) {
		svg {
			height: 30px;
			left: 1rem;
			width: 30px;
		}

		label {
			left: 4rem;
			top: 10px;
		}

		input {
			@include text(22, 32);
			padding-left: 4rem;
			padding-top: 1.5rem;
		}
	}
}
