/*
 * text($font-size, $line-height(optional))
 * calculates font-size and line-height in rem
 * $line-height argument is optional, and can be a different value in $line-height-scale or a number value
 */
@mixin text($font-size, $line-height: null) {
	$fallback-line-height: 1.5;

	@if (type-of($font-size) == 'number') {
		font-size: calculateRem($font-size);

		@if ($line-height != null) {
			line-height: calulateLineHeight($font-size, $line-height);
		} @else {
			line-height: $fallback-line-height;
		}
	} @else {
		@warn 'Unfortunately, no value could be retrieved from `#{$font-size}`. ' + 'Please make sure it is an integer value (without px)';
	}
}
