/*
 *
 * COLOR VARIABLES
 *
 */

/* Monochromes */
$color-black: #000;
$color-light-black: #333;
$color-neutral-darker: #181922;
$color-neutral-dark: #525259;
$color-neutral-darkish: #999;
$color-neutral-medium: #67738c;
$color-neutral-medium-light: #777;
$color-neutral: #8b8b90;
$color-neutral-light: #b1b1b3;
$color-neutral-lighter: #e3dbd1;
$color-neutral-lighter-2: #ccc;
$color-neutral-lightest: #f0ebe6;
$color-neutral-lightest-2: #f2f2f2;
$color-white: #fff;
$color-gray-warm: #f6f3f1;
$color-gray-warm-medium: #d9d9d9;
$color-gray-warmer: #e0e0e0;
$color-gray-warm-dark: #c9cbcb;
$color-gray: #cdcdcd;
$color-gray-dark: #989898;
$color-gray-light: #eee;
$color-gray-extra-light: #f5f5f5;

/* Greens */
$color-green-dark: #4e8209;
$color-green: #64a70b;
$color-green-light: #7cba25;
$color-green-lighter: #a1d45f;
$color-green-lightest: #c7e6a1;
$color-green-darker: #5a8027;
$color-green-darker-2: #508117;
$color-green-darker-3: #7db338;
$color-green-mid-light: #e3f2d0;

/* Blues */
$color-blue-darker: #1b3d6d;
$color-blue-dark: #2178bc;
$color-blue-darkish: #0d2442;
$color-blue-darken: #1b3d6d;
$color-blue-medium: #0073cf;
$color-blue-medium-2: #0064a4;
$color-blue: #53b9d1;
$color-blue-light: #2bb9d9;
$color-blue-lighter: #74d3e8;
$color-blue-lightest: #eefafe;
$color-blue-links: #046791;
$color-blue-turquoise: #00b0ca;
$color-blue-teal: #0083b3;

/* Blue Gradient Colors */
$color-blue-gradient-one: #003a69;
$color-blue-gradient-two: #01508f;
$color-blue-gradient-three: #00b0ca;
$color-blue-gradient-four: #024;
$color-blue-gradient-five: #05a;
$color-blue-gradient-six: #0083b3;

/* Other */
$color-red: #ab405b;
$color-critical: #e52b24;
$color-warning: #ffd905;
$color-gold: #fecc07;
$color-pink: #ff4d6d;

/* Deprecated */
$color-light-gray: #dddddd;
