.featured-providers-result {
	@include text(14, 20);
	background-color: $color-neutral-lightest-2;
	color: $color-neutral-darker;
	overflow: hidden;
	padding: 0;
	position: relative;
	border-radius: 0;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;

	p {
		@include text(14, 20);
		color: $color-neutral-darker;
	}

	.btn {
		@include text(14, 20);
		border: 1px solid $color-neutral-darker;
		border-radius: 2px;
		margin: 0;
		padding: 0.625rem 0.25rem 0.5rem;
	}

	a.result-details {
		position: relative;
		display: block;
		height: 100%;
		padding: 0.5rem 1rem 0.75rem;
		text-decoration: none;

		.result-name {
			color: $color-neutral-darker;
		}

		&:hover,
		&:focus {
			background-color: transparent;

			.result-name {
				text-decoration: underline;
			}

			.new-patient-text {
				color: $color-neutral;

				&.accepting {
					color: $color-neutral-darker;
				}
			}
		}
	}

	.result-top {
		display: flex;
		flex-direction: column;
	}

	.profile-image-outer {
		&:hover,
		&:focus {
			~ .detail-scheduling-container {
				.result-name {
					text-decoration: underline;
				}
			}
		}
	}

	.profile-image-container {
		display: block;
		height: 0;
		overflow: hidden;
		padding-bottom: 80%;
		position: relative;
		width: 100%;
	}

	.profile-image-bg {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		img {
			height: 100%;
			object-fit: cover;
			object-position: center center;
			width: 100%;
		}
	}

	.profile-image {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		img {
			height: 100%;
			object-fit: cover;
			object-position: center 25%;
			width: 100%;
		}
	}

	.result-details {
		color: $color-neutral-darker;
		padding: 1.25rem;
		position: relative;
	}

	.distance {
		@include text(14, 20);
		color: $color-neutral;
		padding: 0 0 0.75rem;
	}

	.result-name {
		@include text(18, 24);
		color: $color-neutral-darker;
		display: block;
		font-weight: $font-weight-regular;
		margin-bottom: 0.25rem;
	}

	.detail-scheduling-container {
		display: flex;
		flex: 1;
		flex-direction: column;

		&.hide-miles-away {
			padding-top: 0.5rem;
		}
	}

	.new-patient-text {
		@include text(14, 17);
		color: $color-neutral;
		font-weight: $font-weight-light;
		padding-top: 1px;

		&.accepting {
			color: $color-neutral-darker;
		}
	}

	.upcoming-availability {
		font-size: 13px;
		line-height: 16px;
		margin-bottom: 0;
		margin-top: 5px;

		span {
			display: block;
		}
	}

	.location-scheduling {
		margin-top: auto;
	}

	.location-name,
	.location-address,
	.scheduling-phone {
		@include text(18, 24);
	}

	.location-name {
		margin: 8px 0 2px;
	}

	.location-address {
		font-weight: $font-weight-light;
	}

	.scheduling-content {
		padding: 0 1rem 0.75rem;
	}

	.scheduling-phone {
		border-top: 1px solid #ccc;
		padding: 8px 0;
		margin: 8px 0;

		a {
			color: #000;
			font-weight: $font-weight-regular;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.request-appointment {
		justify-content: center;
		margin-bottom: 8px;

		&:last-of-type {
			margin-bottom: 3px;
		}
	}

	.scheduling-grid {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.scheduling-link {
		@include text(12, 16);
		display: flex;
		flex-direction: column;
		flex: 0 1 calc(50% - 6px);
		max-width: calc(50% - 6px);
		margin-bottom: 8px;
		background-color: $color-white;
		color: $color-neutral-darker;
		text-align: center;
		text-transform: uppercase;
		padding: 4px 0.5rem;

		&:hover,
		&:focus {
			background-color: $color-black;
			color: $color-white;
		}

		&:only-child {
			flex: 1;
			max-width: 100%;
		}

		&.horizontal-only {
			display: none;
		}
	}

	.scheduling-view-all {
		margin-bottom: 8px;
		justify-content: center;
	}

	.providers-view-all {
		margin-bottom: 0;
	}
}
