/*
 *
 * TYPOGRAPHY FUNCTIONS
 *
 */
/*
 * calculateRem($font-size, $base)
 * convert absolute (px) into relative em (rem) values
 * assumes a 16px baseline; override if in a different context
 * -----
 * calculateRem(32)      ==> 0.5rem
 * calculateRem(48, 12)  ==> 4rem
 */
/*
 * calulateLineHeight($font-size, $line-height)
 * convert absolute (px) from corresponding line-heights map into unitless line-height values
 * $font-size argument must be an integer value (without px)
 * $line-height argument must be an integer value (without px)
 */
/*
 * loader($color, $height(optional, default 26px), $stroke(optional, default 2px))
 * adds generic loader as :after psuedo element
 * $color must be passed. $height and $stroke have defaults, but can be passed in as needed.
 */
/*
 * text($font-size, $line-height(optional))
 * calculates font-size and line-height in rem
 * $line-height argument is optional, and can be a different value in $line-height-scale or a number value
 */
/*
 *
 * BREAKPOINTS MAP
 *
 */
/*
 *
 * COLOR VARIABLES
 *
 */
/* Monochromes */
/* Greens */
/* Blues */
/* Blue Gradient Colors */
/* Other */
/* Deprecated */
/* Box Shadow */
/*
 *
 * TYPOGRAPHY VARIABLES AND MAPS
 *
 */
@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/Gotham-XLight.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/Gotham-XLightItalic.otf") format("opentype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/Gotham-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/Gotham-LightItalic.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/Gotham-Book.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/Gotham-BookItalic.otf") format("opentype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/Gotham-Medium.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/Gotham-MediumItalic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/Gotham-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/Gotham-BoldItalic.otf") format("opentype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/Gotham-Black.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/Gotham-BlackItalic.otf") format("opentype");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/Gotham-Ultra.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Gotham";
  src: url("/assets/fonts/gotham/Gotham-UltraItalic.otf") format("opentype");
  font-weight: 900;
  font-style: italic;
}
.featured-providers {
  /* Box sizing rules */
  /* Remove default padding */
  /* Remove default margin */
  /* Set core body defaults */
  /* Remove list styles on ul, ol elements with a class attribute */
  /* A elements that don't have a class get default styles */
  /* Make images easier to work with */
  /* Natural flow and rhythm in articles by default */
  /* Inherit fonts for inputs and buttons */
  /* Strip border, outline, and padding from unclassed buttons */
  /* Remove all animations and transitions for people that prefer not to see them */
  container-type: inline-size;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 2rem;
  position: relative;
}
.featured-providers *,
.featured-providers *::before,
.featured-providers *::after {
  box-sizing: border-box;
}
.featured-providers ul[class],
.featured-providers ol[class] {
  padding: 0;
}
.featured-providers body,
.featured-providers h1,
.featured-providers h2,
.featured-providers h3,
.featured-providers h4,
.featured-providers p,
.featured-providers ul,
.featured-providers ol,
.featured-providers li,
.featured-providers figure,
.featured-providers figcaption,
.featured-providers blockquote,
.featured-providers dl,
.featured-providers dd {
  margin: 0;
}
.featured-providers body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}
.featured-providers body.body-fixed {
  overflow-y: hidden;
  position: fixed;
  top: 0;
  width: 100%;
}
.featured-providers ul[class],
.featured-providers ol[class] {
  list-style: none;
}
.featured-providers a:not([class]) {
  text-decoration-skip-ink: auto;
}
.featured-providers img {
  display: block;
  max-width: 100%;
}
.featured-providers article > * + * {
  margin-top: 1em;
}
.featured-providers input,
.featured-providers button,
.featured-providers textarea,
.featured-providers select {
  font: inherit;
}
.featured-providers button {
  background: transparent;
  border: 0;
  padding: 0;
}
@media (prefers-reduced-motion: reduce) {
  .featured-providers * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
    transition-duration: 0.01ms !important;
  }
}
.featured-providers .featured-providers-input {
  margin-bottom: 1rem;
  position: relative;
}
.featured-providers .featured-providers-input + .inner-container .featured-providers-result {
  margin-top: -1rem;
}
.featured-providers .featured-providers-input svg {
  position: absolute;
  height: 25px;
  width: 25px;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  fill: #000;
  z-index: 2;
}
.featured-providers .featured-providers-input label {
  font-size: 0.75rem;
  line-height: 1.5;
  color: #181922;
  position: absolute;
  left: 2.25rem;
  top: 10px;
  z-index: 2;
}
.featured-providers .featured-providers-input input {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  background-color: #f2f2f2;
  border: none;
  border-radius: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #000;
  display: flex;
  height: 60px;
  margin: 0;
  padding: 1.5rem 1.25rem 5px 2.25rem;
  width: 100%;
}
.featured-providers .featured-providers-input input::placeholder {
  color: #8b8b90;
}
.featured-providers .featured-providers-input .react-autosuggest__container {
  position: relative;
  z-index: 1;
}
.featured-providers .featured-providers-input .react-autosuggest__input--open + .react-autosuggest__suggestions-container, .featured-providers .featured-providers-input .react-autosuggest__input:focus + .react-autosuggest__suggestions-container {
  display: block;
}
.featured-providers .featured-providers-input .location-search-input:focus + .react-autosuggest__suggestions-container {
  display: block;
}
.featured-providers .featured-providers-input .react-autosuggest__suggestions-container {
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px -5px 23px 4px rgba(0, 0, 0, 0.15);
  display: none;
  margin-top: -20px;
  padding: 30px 10px 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: -1;
}
.featured-providers .featured-providers-input .react-autosuggest__suggestions-container [aria-selected=true] .typeahead-suggestion {
  background-color: #f2f2f2;
  color: #181922;
}
.featured-providers .featured-providers-input .react-autosuggest__suggestions-container .filter-title {
  color: #525259;
}
.featured-providers .featured-providers-input .typeahead-suggestion {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  color: #181922;
  display: flex;
  justify-content: space-between;
  margin: 0 0 0.75rem;
  padding: 0 0 0 0.5rem;
  padding: 0.625rem 1.25rem;
  width: 100%;
}
.featured-providers .featured-providers-input .typeahead-suggestion:hover, .featured-providers .featured-providers-input .typeahead-suggestion:focus, .featured-providers .featured-providers-input .typeahead-suggestion[aria-selected=true] {
  background-color: #f2f2f2;
  cursor: pointer;
}
.featured-providers .featured-providers-input .typeahead-suggestion mark {
  background-color: #a1d45f;
}
.featured-providers .featured-providers-input .typeahead-suggestion .suggestion-name {
  flex-grow: 1;
}
.featured-providers .featured-providers-input .typeahead-suggestion .suggestion-number {
  padding-left: 1rem;
}
@container (min-width: 250px) {
  .featured-providers .featured-providers-input svg {
    height: 30px;
    left: 1rem;
    width: 30px;
  }
  .featured-providers .featured-providers-input label {
    left: 4rem;
    top: 10px;
  }
  .featured-providers .featured-providers-input input {
    font-size: 1.375rem;
    line-height: 1.4545454545;
    padding-left: 4rem;
    padding-top: 1.5rem;
  }
}
.featured-providers .featured-providers-result {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  background-color: #f2f2f2;
  color: #181922;
  overflow: hidden;
  padding: 0;
  position: relative;
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.featured-providers .featured-providers-result p {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #181922;
}
.featured-providers .featured-providers-result .btn {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  border: 1px solid #181922;
  border-radius: 2px;
  margin: 0;
  padding: 0.625rem 0.25rem 0.5rem;
}
.featured-providers .featured-providers-result a.result-details {
  position: relative;
  display: block;
  height: 100%;
  padding: 0.5rem 1rem 0.75rem;
  text-decoration: none;
}
.featured-providers .featured-providers-result a.result-details .result-name {
  color: #181922;
}
.featured-providers .featured-providers-result a.result-details:hover, .featured-providers .featured-providers-result a.result-details:focus {
  background-color: transparent;
}
.featured-providers .featured-providers-result a.result-details:hover .result-name, .featured-providers .featured-providers-result a.result-details:focus .result-name {
  text-decoration: underline;
}
.featured-providers .featured-providers-result a.result-details:hover .new-patient-text, .featured-providers .featured-providers-result a.result-details:focus .new-patient-text {
  color: #8b8b90;
}
.featured-providers .featured-providers-result a.result-details:hover .new-patient-text.accepting, .featured-providers .featured-providers-result a.result-details:focus .new-patient-text.accepting {
  color: #181922;
}
.featured-providers .featured-providers-result .result-top {
  display: flex;
  flex-direction: column;
}
.featured-providers .featured-providers-result .profile-image-outer:hover ~ .detail-scheduling-container .result-name, .featured-providers .featured-providers-result .profile-image-outer:focus ~ .detail-scheduling-container .result-name {
  text-decoration: underline;
}
.featured-providers .featured-providers-result .profile-image-container {
  display: block;
  height: 0;
  overflow: hidden;
  padding-bottom: 80%;
  position: relative;
  width: 100%;
}
.featured-providers .featured-providers-result .profile-image-bg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.featured-providers .featured-providers-result .profile-image-bg img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
  width: 100%;
}
.featured-providers .featured-providers-result .profile-image {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.featured-providers .featured-providers-result .profile-image img {
  height: 100%;
  object-fit: cover;
  object-position: center 25%;
  width: 100%;
}
.featured-providers .featured-providers-result .result-details {
  color: #181922;
  padding: 1.25rem;
  position: relative;
}
.featured-providers .featured-providers-result .distance {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  color: #8b8b90;
  padding: 0 0 0.75rem;
}
.featured-providers .featured-providers-result .result-name {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #181922;
  display: block;
  font-weight: 400;
  margin-bottom: 0.25rem;
}
.featured-providers .featured-providers-result .detail-scheduling-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.featured-providers .featured-providers-result .detail-scheduling-container.hide-miles-away {
  padding-top: 0.5rem;
}
.featured-providers .featured-providers-result .new-patient-text {
  font-size: 0.875rem;
  line-height: 1.2142857143;
  color: #8b8b90;
  font-weight: 300;
  padding-top: 1px;
}
.featured-providers .featured-providers-result .new-patient-text.accepting {
  color: #181922;
}
.featured-providers .featured-providers-result .upcoming-availability {
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 0;
  margin-top: 5px;
}
.featured-providers .featured-providers-result .upcoming-availability span {
  display: block;
}
.featured-providers .featured-providers-result .location-scheduling {
  margin-top: auto;
}
.featured-providers .featured-providers-result .location-name,
.featured-providers .featured-providers-result .location-address,
.featured-providers .featured-providers-result .scheduling-phone {
  font-size: 1.125rem;
  line-height: 1.3333333333;
}
.featured-providers .featured-providers-result .location-name {
  margin: 8px 0 2px;
}
.featured-providers .featured-providers-result .location-address {
  font-weight: 300;
}
.featured-providers .featured-providers-result .scheduling-content {
  padding: 0 1rem 0.75rem;
}
.featured-providers .featured-providers-result .scheduling-phone {
  border-top: 1px solid #ccc;
  padding: 8px 0;
  margin: 8px 0;
}
.featured-providers .featured-providers-result .scheduling-phone a {
  color: #000;
  font-weight: 400;
  text-decoration: none;
}
.featured-providers .featured-providers-result .scheduling-phone a:hover {
  text-decoration: underline;
}
.featured-providers .featured-providers-result .request-appointment {
  justify-content: center;
  margin-bottom: 8px;
}
.featured-providers .featured-providers-result .request-appointment:last-of-type {
  margin-bottom: 3px;
}
.featured-providers .featured-providers-result .scheduling-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.featured-providers .featured-providers-result .scheduling-link {
  font-size: 0.75rem;
  line-height: 1.3333333333;
  display: flex;
  flex-direction: column;
  flex: 0 1 calc(50% - 6px);
  max-width: calc(50% - 6px);
  margin-bottom: 8px;
  background-color: #fff;
  color: #181922;
  text-align: center;
  text-transform: uppercase;
  padding: 4px 0.5rem;
}
.featured-providers .featured-providers-result .scheduling-link:hover, .featured-providers .featured-providers-result .scheduling-link:focus {
  background-color: #000;
  color: #fff;
}
.featured-providers .featured-providers-result .scheduling-link:only-child {
  flex: 1;
  max-width: 100%;
}
.featured-providers .featured-providers-result .scheduling-link.horizontal-only {
  display: none;
}
.featured-providers .featured-providers-result .scheduling-view-all {
  margin-bottom: 8px;
  justify-content: center;
}
.featured-providers .featured-providers-result .providers-view-all {
  margin-bottom: 0;
}
.featured-providers.noto-serif {
  font-family: "Noto Serif", serif;
}
.featured-providers.noto-sans-serif {
  font-family: "Noto Sans", sans-serif;
}
.featured-providers.lato {
  font-family: "Lato", sans-serif;
}
.featured-providers.loading {
  overflow: hidden;
}
.featured-providers.loading.no-border-radius .featured-providers-input:after,
.featured-providers.loading.no-border-radius .inner-container:after {
  border-radius: 0;
}
.featured-providers.loading:before {
  content: "";
  position: absolute;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 50%, rgba(255, 255, 255, 0) 100%);
  height: 100%;
  width: 100%;
  z-index: 1;
  animation: shimmer 1.2s infinite;
  z-index: 3;
}
.featured-providers.loading .featured-providers-input {
  position: relative;
}
.featured-providers.loading .featured-providers-input:after {
  background-color: #f2f2f2;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  content: "";
  display: block;
  height: calc(100% + 1px);
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
.featured-providers.loading .inner-container {
  position: relative;
}
.featured-providers.loading .inner-container:after {
  background-color: #f2f2f2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}
@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.featured-providers.muted-blues.multi .featured-providers-input {
  border-bottom-color: #032f40;
}
.featured-providers.muted-blues.multi .scheduling-link {
  border-color: #032f40;
}
.featured-providers.muted-blues .featured-providers-input svg {
  fill: #7dadd0;
}
.featured-providers.muted-blues .featured-providers-input input {
  color: #1e5a7e;
}
.featured-providers.muted-blues .action-cta {
  background-color: #032f40;
  color: #fff;
}
.featured-providers.muted-blues .action-cta:hover, .featured-providers.muted-blues .action-cta:focus {
  background-color: #1e5a7e;
}
.featured-providers.muted-blues .action-cta.cta-secondary {
  background-color: #b1cbdd;
  color: #032f40;
}
.featured-providers.muted-blues .action-cta.cta-secondary:hover, .featured-providers.muted-blues .action-cta.cta-secondary:focus {
  background-color: #7dadd0;
}
.featured-providers.muted-blues .scheduling-link {
  background-color: #fff;
  color: #032f40;
}
.featured-providers.muted-blues .scheduling-link:hover, .featured-providers.muted-blues .scheduling-link:focus {
  background-color: #032f40;
  color: #fff;
}
.featured-providers.muted-blues .featured-providers-result a.result-details:hover .new-patient-text, .featured-providers.muted-blues .featured-providers-result a.result-details:focus .new-patient-text {
  color: #1e5a7e;
}
.featured-providers.muted-blues .featured-providers-result a.result-details:hover .new-patient-text.accepting, .featured-providers.muted-blues .featured-providers-result a.result-details:focus .new-patient-text.accepting {
  color: #1e5a7e;
}
.featured-providers.muted-blues .featured-providers-result a.result-details .distance {
  color: #032f40;
}
.featured-providers.muted-blues .featured-providers-result a.result-details .result-name {
  color: #032f40;
}
.featured-providers.muted-blues .featured-providers-result a.result-details .new-patient-text {
  color: #1e5a7e;
  font-weight: 300;
}
.featured-providers.muted-blues .featured-providers-result a.result-details .new-patient-text.accepting {
  color: #1e5a7e;
  font-weight: 500;
}
.featured-providers.muted-blues .featured-providers-result a.result-details .upcoming-availability {
  color: #032f40;
  font-weight: 600;
}
.featured-providers.muted-blues .need-more-help p {
  color: #032f40;
}
.featured-providers.muted-blues .help-heading {
  color: #032f40;
}
.featured-providers.muted-blues .view-all-call-link {
  color: #1e5a7e;
}
.featured-providers.muted-blues .view-all-call-link:hover, .featured-providers.muted-blues .view-all-call-link:focus {
  color: #032f40;
}
.featured-providers.muted-blues .featured-providers-input .react-autosuggest__suggestions-container [aria-selected=true] .typeahead-suggestion {
  background-color: #b1cbdd;
}
.featured-providers.muted-blues .featured-providers-input .typeahead-suggestion:hover, .featured-providers.muted-blues .featured-providers-input .typeahead-suggestion:focus, .featured-providers.muted-blues .featured-providers-input .typeahead-suggestion[aria-selected=true] {
  background-color: #b1cbdd;
}
.featured-providers.warm-neutrals.loading .featured-providers-input:after,
.featured-providers.warm-neutrals.loading .inner-container:after {
  background-color: #ece1d7;
}
.featured-providers.warm-neutrals.multi .featured-providers-input, .featured-providers.warm-neutrals.continue-search .featured-providers-input {
  border-bottom-color: #443436;
}
.featured-providers.warm-neutrals.multi .result-top, .featured-providers.warm-neutrals.continue-search .result-top {
  background-color: #fff;
}
.featured-providers.warm-neutrals.multi .scheduling-link, .featured-providers.warm-neutrals.continue-search .scheduling-link {
  border-color: #443436;
}
.featured-providers.warm-neutrals .inner-container {
  background-color: #ece1d7;
}
.featured-providers.warm-neutrals .featured-providers-input svg {
  fill: #6f5855;
}
.featured-providers.warm-neutrals .featured-providers-input input {
  background-color: #ece1d7;
  color: #443436;
}
.featured-providers.warm-neutrals .action-cta {
  background-color: #443436;
}
.featured-providers.warm-neutrals .action-cta:hover, .featured-providers.warm-neutrals .action-cta:focus {
  background-color: #6f5855;
}
.featured-providers.warm-neutrals .action-cta.cta-secondary {
  background-color: #cba990;
  color: #443436;
}
.featured-providers.warm-neutrals .action-cta.cta-secondary:hover, .featured-providers.warm-neutrals .action-cta.cta-secondary:focus {
  background-color: #b1a59b;
}
.featured-providers.warm-neutrals .scheduling-link {
  background-color: #fff;
  color: #443436;
}
.featured-providers.warm-neutrals .scheduling-link:hover, .featured-providers.warm-neutrals .scheduling-link:focus {
  background-color: #443436;
  color: #fff;
}
.featured-providers.warm-neutrals .result-top {
  background-color: #ece1d7;
}
.featured-providers.warm-neutrals .featured-providers-result a.result-details:hover .new-patient-text, .featured-providers.warm-neutrals .featured-providers-result a.result-details:focus .new-patient-text {
  color: #443436;
}
.featured-providers.warm-neutrals .featured-providers-result a.result-details:hover .new-patient-text.accepting, .featured-providers.warm-neutrals .featured-providers-result a.result-details:focus .new-patient-text.accepting {
  color: #443436;
}
.featured-providers.warm-neutrals .featured-providers-result a.result-details .distance {
  color: #443436;
}
.featured-providers.warm-neutrals .featured-providers-result a.result-details .result-name {
  color: #443436;
}
.featured-providers.warm-neutrals .featured-providers-result a.result-details .new-patient-text {
  color: #443436;
  font-weight: 300;
}
.featured-providers.warm-neutrals .featured-providers-result a.result-details .new-patient-text.accepting {
  color: #443436;
  font-weight: 500;
}
.featured-providers.warm-neutrals .featured-providers-result a.result-details .upcoming-availability {
  color: #443436;
  font-weight: 600;
}
.featured-providers.warm-neutrals .need-more-help p {
  color: #443436;
}
.featured-providers.warm-neutrals .help-heading {
  color: #443436;
}
.featured-providers.warm-neutrals .view-all-call-link {
  color: #6f5855;
}
.featured-providers.warm-neutrals .view-all-call-link:hover, .featured-providers.warm-neutrals .view-all-call-link:focus {
  color: #443436;
}
.featured-providers.warm-neutrals .featured-providers-input .react-autosuggest__suggestions-container [aria-selected=true] .typeahead-suggestion {
  background-color: #ece1d7;
}
.featured-providers.warm-neutrals .featured-providers-input .typeahead-suggestion:hover, .featured-providers.warm-neutrals .featured-providers-input .typeahead-suggestion:focus, .featured-providers.warm-neutrals .featured-providers-input .typeahead-suggestion[aria-selected=true] {
  background-color: #ece1d7;
}
.featured-providers.no-border-radius.multi .inner-container,
.featured-providers.no-border-radius.multi .featured-providers-result, .featured-providers.no-border-radius.continue-search .inner-container,
.featured-providers.no-border-radius.continue-search .featured-providers-result {
  border-radius: 0;
}
.featured-providers.no-border-radius .inner-container,
.featured-providers.no-border-radius .featured-providers-input input,
.featured-providers.no-border-radius .featured-providers-result,
.featured-providers.no-border-radius .action-cta {
  border-radius: 0;
}
.featured-providers.no-border-radius .featured-providers-input .react-autosuggest__suggestions-container {
  border-radius: 0;
}
.featured-providers.no-border-radius .featured-providers-input .react-autosuggest__suggestions-container [aria-selected=true] .typeahead-suggestion {
  border-radius: 0;
}
.featured-providers.no-border-radius .featured-providers-input .typeahead-suggestion:hover, .featured-providers.no-border-radius .featured-providers-input .typeahead-suggestion:focus, .featured-providers.no-border-radius .featured-providers-input .typeahead-suggestion[aria-selected=true] {
  border-radius: 0;
}
.featured-providers.multi .inner-container, .featured-providers.continue-search .inner-container {
  padding: 1.5rem 1rem;
}
.featured-providers.multi .featured-providers-result, .featured-providers.continue-search .featured-providers-result {
  border-radius: 10px;
  background-color: #fff;
}
.featured-providers.multi .scheduling-link, .featured-providers.continue-search .scheduling-link {
  border: 1px solid #181922;
}
.featured-providers.multi .featured-providers-input {
  border-bottom: 1px solid #1b1b1b;
  margin-bottom: 0;
}
.featured-providers.multi .featured-providers-input + .inner-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 1rem;
}
.featured-providers.multi .featured-providers-input + .inner-container .featured-providers-result {
  margin-top: 0;
}
.featured-providers .inner-container {
  background-color: #f2f2f2;
  border-radius: 10px;
}
.featured-providers .expanded-scheduling .result-name {
  margin-top: 1rem;
}
.featured-providers .featured-providers-title {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  color: #333;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-top: 2rem;
  margin-left: 0.75rem;
}
.featured-providers .scheduling-help-text {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  padding: 0 1rem;
}
.featured-providers .scheduling-help-text a {
  color: #2178bc;
}
.featured-providers .location-scheduling {
  padding: 0 1rem 1rem;
  transition: background-color 0.1s ease-in-out;
}
.featured-providers .location-scheduling .action-cta {
  margin-bottom: 1rem;
}
.featured-providers .location-scheduling .action-cta:last-of-type {
  margin-bottom: 0;
}
.featured-providers .schedule-or-call {
  padding: 0;
}
.featured-providers .call-link {
  display: none;
}
.featured-providers .need-more-help p {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #181922;
  margin-bottom: 1.25rem;
}
.featured-providers .need-more-help .action-cta {
  display: inline-flex;
  width: auto;
}
.featured-providers .action-cta {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  align-items: center;
  background-color: #1b1b1b;
  border-radius: 4px;
  color: #fff;
  display: flex;
  font-weight: 400;
  justify-content: flex-start;
  margin-bottom: 2rem;
  padding: 10px 1rem;
  text-decoration: none;
  width: 100%;
}
.featured-providers .action-cta:hover, .featured-providers .action-cta:focus {
  background-color: #474747;
}
.featured-providers .action-cta.cta-secondary {
  background-color: #dbdbdb;
  color: #1b1b1b;
}
.featured-providers .action-cta.cta-secondary:hover, .featured-providers .action-cta.cta-secondary:focus {
  background-color: #b3b3b3;
}
.featured-providers .view-all-call-link {
  font-size: 1.125rem;
  line-height: 1.3333333333;
  color: #000;
  text-decoration: underline;
}
.featured-providers .view-all-call-link:hover, .featured-providers .view-all-call-link:focus {
  color: #333;
}
.featured-providers .view-more-link {
  background-color: transparent;
  border: 1px solid #b1b1b3;
  border-radius: 17px;
  color: #181922;
  justify-content: center;
  height: 34px;
  margin-bottom: 1rem;
}
.featured-providers .help-heading {
  font-size: 1.375rem;
  line-height: 1.4545454545;
  font-weight: 400;
  margin: 0 0 0.25rem;
}
@container (min-width: 600px) {
  .featured-providers.single.muted-blues .featured-providers-input input {
    border-bottom-color: #032f40;
  }
  .featured-providers.single.warm-neutrals .featured-providers-result {
    background-color: #ece1d7;
  }
  .featured-providers.single.warm-neutrals .featured-providers-input input {
    border-bottom-color: #443436;
  }
  .featured-providers.single.no-border-radius .featured-providers-result .profile-image-container {
    border-radius: 0;
  }
  .featured-providers.single .inner-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .featured-providers.single .inner-container .featured-providers-result {
    margin-top: 0;
  }
  .featured-providers.single .featured-providers-input {
    margin-bottom: 0;
  }
  .featured-providers.single .featured-providers-input input {
    border-bottom: 1px solid #1b1b1b;
  }
  .featured-providers.single .featured-providers-result {
    padding: 1rem;
  }
  .featured-providers.single .featured-providers-result .result-top {
    display: flex;
    flex-direction: row;
  }
  .featured-providers.single .featured-providers-result a.result-details {
    height: auto;
    flex: 1;
  }
  .featured-providers.single .featured-providers-result a.result-details .result-name {
    font-size: 1.375rem;
    line-height: 1.4545454545;
    font-weight: 600;
  }
  .featured-providers.single .featured-providers-result .profile-image-outer {
    flex: 0 1 35%;
    max-width: 300px;
  }
  .featured-providers.single .featured-providers-result .profile-image-container {
    border-radius: 10px;
  }
  .featured-providers.single .featured-providers-result .location-scheduling {
    margin-top: 0;
    padding-top: 0.75rem;
  }
  .featured-providers.single .featured-providers-result .scheduling-grid {
    justify-content: flex-start;
    margin-left: -3px;
    margin-right: -3px;
  }
  .featured-providers.single .featured-providers-result .scheduling-link {
    flex: 0 1 calc(25% - 6px);
    max-width: calc(25% - 6px);
    margin: 0 3px 6px;
  }
  .featured-providers.single .featured-providers-result .scheduling-link.horizontal-only {
    display: flex;
  }
  .featured-providers.single .featured-providers-result .view-all-container {
    display: flex;
    justify-content: space-between;
  }
  .featured-providers.single .featured-providers-result .view-all-container .scheduling-view-all,
  .featured-providers.single .featured-providers-result .view-all-container .providers-view-all {
    flex: 0 1 calc(50% - 8px);
    max-width: calc(50% - 8px);
    margin-bottom: 0;
  }
  .featured-providers.horizontal.multi .search-results-container {
    justify-content: space-between;
  }
  .featured-providers.horizontal.multi .featured-providers-result {
    flex: calc(50% - 12px);
    max-width: calc(50% - 12px);
  }
  .featured-providers.horizontal.multi .featured-providers-result:nth-of-type(3n + 1), .featured-providers.horizontal.multi .featured-providers-result:nth-of-type(3n + 2), .featured-providers.horizontal.multi .featured-providers-result:nth-of-type(3n + 3) {
    margin-left: 0;
    margin-right: 0;
  }
  .featured-providers.horizontal.multi .featured-providers-result .result-top {
    display: flex;
    flex-direction: row;
  }
  .featured-providers.horizontal.multi .featured-providers-result .profile-image-outer {
    flex: 0 1 40%;
    min-width: 40%;
  }
  .featured-providers.horizontal.multi .featured-providers-result .profile-image-container {
    height: 100%;
    padding-bottom: 0;
  }
  .featured-providers.multi .inner-container {
    width: 100%;
    padding: 1.25rem 1.25rem 0;
  }
  .featured-providers.multi .inner-container .need-more-help {
    padding-bottom: 2rem;
  }
  .featured-providers.multi .search-results-container {
    align-self: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
  .featured-providers.multi .expanded-scheduling .featured-providers-result {
    display: flex;
    flex: 100%;
    max-width: 100%;
  }
  .featured-providers.multi .featured-providers-result {
    flex: calc(50% - 12px);
    max-width: calc(50% - 12px);
  }
  .featured-providers.multi .featured-providers-result:nth-of-type(even) {
    margin-left: 12px;
    margin-right: 0;
  }
  .featured-providers.multi .featured-providers-result:nth-of-type(odd) {
    margin-left: 0;
    margin-right: 12px;
  }
  .featured-providers.multi .result-top {
    height: 100%;
  }
  .featured-providers.multi .distance {
    padding-bottom: 0.5rem;
  }
  .featured-providers.multi .result-name {
    font-size: 1.125rem;
    line-height: 1.3333333333;
    font-weight: 600;
    margin-bottom: 0.125rem;
  }
  .featured-providers.continue-search .inner-container {
    background-color: #f2f2f2;
    padding: 1.25rem 1.25rem 0;
  }
  .featured-providers.continue-search .featured-providers-result {
    background-color: #fff;
    margin-bottom: 1.25rem;
  }
}
@container (min-width: 768px) {
  .featured-providers.single .featured-providers-result .detail-scheduling-container {
    display: flex;
  }
  .featured-providers.multi .featured-providers-result {
    flex: calc(33.333% - 16px);
    max-width: calc(33.333% - 16px);
  }
  .featured-providers.multi .featured-providers-result:nth-of-type(3n + 1) {
    margin-left: 0;
    margin-right: 12px;
  }
  .featured-providers.multi .featured-providers-result:nth-of-type(3n + 2) {
    margin-left: 12px;
    margin-right: 12px;
  }
  .featured-providers.multi .featured-providers-result:nth-of-type(3n + 3) {
    margin-left: 12px;
    margin-right: 0;
  }
}
@container (min-width: 875px) {
  .featured-providers.multi .inner-container {
    display: flex;
    width: 100%;
    padding: 1.25rem 1.25rem 0;
  }
  .featured-providers.multi .inner-container .need-more-help {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 1.25rem 1rem;
  }
}
@container (min-width: 1268px) {
  .featured-providers.horizontal.multi .search-results-container {
    justify-content: space-between;
  }
  .featured-providers.horizontal.multi .featured-providers-result {
    flex: calc(33.333% - 16px);
    max-width: calc(33.333% - 16px);
  }
  .featured-providers.horizontal.multi .featured-providers-result:nth-of-type(3n + 1) {
    margin-left: 0;
    margin-right: 12px;
  }
  .featured-providers.horizontal.multi .featured-providers-result:nth-of-type(3n + 2) {
    margin-left: 12px;
    margin-right: 12px;
  }
  .featured-providers.horizontal.multi .featured-providers-result:nth-of-type(3n + 3) {
    margin-left: 12px;
    margin-right: 0;
  }
}