.featured-providers {
	@import '../../global/helpers/reset';
	@import 'featured-providers-input';
	@import 'featured-providers-result';
	container-type: inline-size;
	font-family: $font-family-sans-serif;
	margin-bottom: 2rem;
	position: relative;

	&.noto-serif {
		font-family: $font-family-serif;
	}

	&.noto-sans-serif {
		font-family: $font-family-noto-sans-serif;
	}

	&.lato {
		font-family: $font-family-lato;
	}

	&.loading {
		overflow: hidden;

		&.no-border-radius {
			.featured-providers-input,
			.inner-container {
				&:after {
					border-radius: 0;
				}
			}
		}

		&:before {
			content: '';
			position: absolute;
			background: linear-gradient(
				90deg,
				rgba(255, 255, 255, 0) 0%,
				rgba(255, 255, 255, 0.4) 50%,
				rgba(255, 255, 255, 0) 100%
			);
			height: 100%;
			width: 100%;
			z-index: 1;
			animation: shimmer 1.2s infinite;
			z-index: 3;
		}

		.featured-providers-input {
			position: relative;

			&:after {
				background-color: $color-neutral-lightest-2;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
				content: '';
				display: block;
				height: calc(100% + 1px);
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 2;
			}
		}

		.inner-container {
			position: relative;

			&:after {
				background-color: $color-neutral-lightest-2;
				border-bottom-left-radius: 10px;
				border-bottom-right-radius: 10px;
				content: '';
				display: block;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 2;
			}
		}

		@keyframes shimmer {
			0% {
				transform: translateX(-100%);
			}
			100% {
				transform: translateX(100%);
			}
		}
	}

	&.muted-blues {
		&.multi {
			.featured-providers-input {
				border-bottom-color: #032f40;
			}

			.scheduling-link {
				border-color: #032f40;
			}
		}

		.featured-providers-input {
			svg {
				fill: #7dadd0;
			}

			input {
				color: #1e5a7e;
			}
		}

		.action-cta {
			background-color: #032f40;
			color: $color-white;

			&:hover,
			&:focus {
				background-color: #1e5a7e;
			}

			&.cta-secondary {
				background-color: #b1cbdd;
				color: #032f40;

				&:hover,
				&:focus {
					background-color: #7dadd0;
				}
			}
		}

		.scheduling-link {
			background-color: $color-white;
			color: #032f40;

			&:hover,
			&:focus {
				background-color: #032f40;
				color: $color-white;
			}
		}

		.featured-providers-result {
			a.result-details {
				&:hover,
				&:focus {
					.new-patient-text {
						color: #1e5a7e;

						&.accepting {
							color: #1e5a7e;
						}
					}
				}

				.distance {
					color: #032f40;
				}

				.result-name {
					color: #032f40;
				}

				.new-patient-text {
					color: #1e5a7e;
					font-weight: $font-weight-light;

					&.accepting {
						color: #1e5a7e;
						font-weight: $font-weight-medium;
					}
				}

				.upcoming-availability {
					color: #032f40;
					font-weight: $font-weight-semi-bold;
				}
			}
		}

		.need-more-help {
			p {
				color: #032f40;
			}
		}

		.help-heading {
			color: #032f40;
		}

		.view-all-call-link {
			color: #1e5a7e;

			&:hover,
			&:focus {
				color: #032f40;
			}
		}

		.featured-providers-input {
			.react-autosuggest__suggestions-container {
				[aria-selected='true'] {
					.typeahead-suggestion {
						background-color: #b1cbdd;
					}
				}
			}

			.typeahead-suggestion {
				&:hover,
				&:focus,
				&[aria-selected='true'] {
					background-color: #b1cbdd;
				}
			}
		}
	}

	&.warm-neutrals {
		&.loading {
			.featured-providers-input,
			.inner-container {
				&:after {
					background-color: #ece1d7;
				}
			}
		}

		&.multi,
		&.continue-search {
			.featured-providers-input {
				border-bottom-color: #443436;
			}

			.result-top {
				background-color: $color-white;
			}

			.scheduling-link {
				border-color: #443436;
			}
		}

		.inner-container {
			background-color: #ece1d7;
		}

		.featured-providers-input {
			svg {
				fill: #6f5855;
			}

			input {
				background-color: #ece1d7;
				color: #443436;
			}
		}

		.action-cta {
			background-color: #443436;

			&:hover,
			&:focus {
				background-color: #6f5855;
			}

			&.cta-secondary {
				background-color: #cba990;
				color: #443436;

				&:hover,
				&:focus {
					background-color: #b1a59b;
				}
			}
		}

		.scheduling-link {
			background-color: $color-white;
			color: #443436;

			&:hover,
			&:focus {
				background-color: #443436;
				color: $color-white;
			}
		}

		.result-top {
			background-color: #ece1d7;
		}

		.featured-providers-result {
			a.result-details {
				&:hover,
				&:focus {
					.new-patient-text {
						color: #443436;

						&.accepting {
							color: #443436;
						}
					}
				}

				.distance {
					color: #443436;
				}

				.result-name {
					color: #443436;
				}

				.new-patient-text {
					color: #443436;
					font-weight: $font-weight-light;

					&.accepting {
						color: #443436;
						font-weight: $font-weight-medium;
					}
				}

				.upcoming-availability {
					color: #443436;
					font-weight: $font-weight-semi-bold;
				}
			}
		}

		.need-more-help {
			p {
				color: #443436;
			}
		}

		.help-heading {
			color: #443436;
		}

		.view-all-call-link {
			color: #6f5855;

			&:hover,
			&:focus {
				color: #443436;
			}
		}
		.featured-providers-input {
			.react-autosuggest__suggestions-container {
				[aria-selected='true'] {
					.typeahead-suggestion {
						background-color: #ece1d7;
					}
				}
			}

			.typeahead-suggestion {
				&:hover,
				&:focus,
				&[aria-selected='true'] {
					background-color: #ece1d7;
				}
			}
		}
	}

	&.no-border-radius {
		&.multi,
		&.continue-search {
			.inner-container,
			.featured-providers-result {
				border-radius: 0;
			}
		}

		.inner-container,
		.featured-providers-input input,
		.featured-providers-result,
		.action-cta {
			border-radius: 0;
		}

		.featured-providers-input {
			.react-autosuggest__suggestions-container {
				border-radius: 0;

				[aria-selected='true'] {
					.typeahead-suggestion {
						border-radius: 0;
					}
				}
			}

			.typeahead-suggestion {
				&:hover,
				&:focus,
				&[aria-selected='true'] {
					border-radius: 0;
				}
			}
		}
	}

	&.multi,
	&.continue-search {
		.inner-container {
			padding: 1.5rem 1rem;
		}

		.featured-providers-result {
			border-radius: 10px;
			background-color: $color-white;
		}

		.scheduling-link {
			border: 1px solid $color-neutral-darker;
		}
	}

	&.multi {
		.featured-providers-input {
			border-bottom: 1px solid #1b1b1b;
			margin-bottom: 0;

			+ .inner-container {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				padding-top: 1rem;

				.featured-providers-result {
					margin-top: 0;
				}
			}
		}
	}

	.inner-container {
		background-color: $color-neutral-lightest-2;
		border-radius: 10px;
	}

	.expanded-scheduling {
		.result-name {
			margin-top: 1rem;
		}
	}

	.featured-providers-title {
		@include text(22, 32);
		color: $color-light-black;
		font-weight: $font-weight-semi-bold;
		margin-bottom: 1rem;
		margin-top: 2rem;
		margin-left: 0.75rem;
	}

	.scheduling-help-text {
		@include text(14, 20);
		padding: 0 1rem;

		a {
			color: $color-blue-dark;
		}
	}

	.location-scheduling {
		padding: 0 1rem 1rem;
		transition: background-color 0.1s ease-in-out;

		.action-cta {
			margin-bottom: 1rem;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.schedule-or-call {
		padding: 0;
	}

	.call-link {
		display: none;
	}

	.need-more-help {
		p {
			@include text(18, 24);
			color: $color-neutral-darker;
			margin-bottom: 1.25rem;
		}

		.action-cta {
			display: inline-flex;
			width: auto;
		}
	}

	.action-cta {
		@include text(18, 24);
		align-items: center;
		background-color: #1b1b1b;
		border-radius: 4px;
		color: $color-white;
		display: flex;
		font-weight: $font-weight-regular;
		justify-content: flex-start;
		margin-bottom: 2rem;
		padding: 10px 1rem;
		text-decoration: none;
		width: 100%;

		&:hover,
		&:focus {
			background-color: #474747;
		}

		&.cta-secondary {
			background-color: #dbdbdb;
			color: #1b1b1b;

			&:hover,
			&:focus {
				background-color: #b3b3b3;
			}
		}
	}

	.view-all-call-link {
		@include text(18, 24);
		color: $color-black;
		text-decoration: underline;

		&:hover,
		&:focus {
			color: $color-light-black;
		}
	}

	.view-more-link {
		background-color: transparent;
		border: 1px solid $color-neutral-light;
		border-radius: 17px;
		color: $color-neutral-darker;
		justify-content: center;
		height: 34px;
		margin-bottom: 1rem;
	}

	.help-heading {
		@include text(22, 32);
		font-weight: $font-weight-regular;
		margin: 0 0 0.25rem;
	}

	@container (min-width: 600px) {
		&.single {
			&.muted-blues {
				.featured-providers-input {
					input {
						border-bottom-color: #032f40;
					}
				}
			}

			&.warm-neutrals {
				.featured-providers-result {
					background-color: #ece1d7;
				}

				.featured-providers-input {
					input {
						border-bottom-color: #443436;
					}
				}
			}

			&.no-border-radius {
				.featured-providers-result {
					.profile-image-container {
						border-radius: 0;
					}
				}
			}

			.inner-container {
				border-top-left-radius: 0;
				border-top-right-radius: 0;

				.featured-providers-result {
					margin-top: 0;
				}
			}

			.featured-providers-input {
				margin-bottom: 0;

				input {
					border-bottom: 1px solid #1b1b1b;
				}
			}

			.featured-providers-result {
				padding: 1rem;

				.result-top {
					display: flex;
					flex-direction: row;
				}

				a.result-details {
					height: auto;
					flex: 1;

					.result-name {
						@include text(22, 32);
						font-weight: $font-weight-semi-bold;
					}
				}

				.profile-image-outer {
					flex: 0 1 35%;
					max-width: 300px;
				}

				.profile-image-container {
					border-radius: 10px;
				}

				.location-scheduling {
					margin-top: 0;
					padding-top: 0.75rem;
				}

				.scheduling-grid {
					justify-content: flex-start;
					margin-left: -3px;
					margin-right: -3px;
				}

				.scheduling-link {
					flex: 0 1 calc(25% - 6px);
					max-width: calc(25% - 6px);
					margin: 0 3px 6px;

					&.horizontal-only {
						display: flex;
					}
				}

				.view-all-container {
					display: flex;
					justify-content: space-between;

					.scheduling-view-all,
					.providers-view-all {
						flex: 0 1 calc(50% - 8px);
						max-width: calc(50% - 8px);
						margin-bottom: 0;
					}
				}
			}
		}

		&.horizontal {
			&.multi {
				.search-results-container {
					justify-content: space-between;
				}

				.featured-providers-result {
					flex: calc(50% - (24px / 2));
					max-width: calc(50% - (24px / 2));

					&:nth-of-type(3n + 1),
					&:nth-of-type(3n + 2),
					&:nth-of-type(3n + 3) {
						margin-left: 0;
						margin-right: 0;
					}

					.result-top {
						display: flex;
						flex-direction: row;
					}

					.profile-image-outer {
						flex: 0 1 40%;
						min-width: 40%;
					}

					.profile-image-container {
						height: 100%;
						padding-bottom: 0;
					}
				}
			}
		}

		&.multi {
			.inner-container {
				width: 100%;
				padding: 1.25rem 1.25rem 0;

				.need-more-help {
					padding-bottom: 2rem;
				}
			}

			.search-results-container {
				align-self: flex-start;
				display: flex;
				flex-wrap: wrap;
				flex: 1;
			}

			.expanded-scheduling {
				.featured-providers-result {
					display: flex;
					flex: 100%;
					max-width: 100%;
				}
			}

			.featured-providers-result {
				flex: calc(50% - (24px / 2));
				max-width: calc(50% - (24px / 2));

				&:nth-of-type(even) {
					margin-left: 12px;
					margin-right: 0;
				}
				&:nth-of-type(odd) {
					margin-left: 0;
					margin-right: 12px;
				}
			}

			.result-top {
				height: 100%;
			}

			.distance {
				padding-bottom: 0.5rem;
			}

			.result-name {
				@include text(18, 24);
				font-weight: $font-weight-semi-bold;
				margin-bottom: 0.125rem;
			}
		}

		&.continue-search {
			.inner-container {
				background-color: $color-neutral-lightest-2;
				padding: 1.25rem 1.25rem 0;
			}

			.featured-providers-result {
				background-color: $color-white;
				margin-bottom: 1.25rem;
			}
		}
	}

	@container (min-width: 768px) {
		&.single {
			.featured-providers-result {
				.detail-scheduling-container {
					display: flex;
				}
			}
		}

		&.multi {
			.featured-providers-result {
				flex: calc(33.333% - (48px / 3));
				max-width: calc(33.333% - (48px / 3));

				&:nth-of-type(3n + 1) {
					margin-left: 0;
					margin-right: 12px;
				}
				&:nth-of-type(3n + 2) {
					margin-left: 12px;
					margin-right: 12px;
				}
				&:nth-of-type(3n + 3) {
					margin-left: 12px;
					margin-right: 0;
				}
			}
		}
	}

	@container (min-width: 875px) {
		&.multi {
			.inner-container {
				display: flex;
				width: 100%;
				padding: 1.25rem 1.25rem 0;

				.need-more-help {
					flex: 0 0 25%;
					max-width: 25%;
					padding: 0 1.25rem 1rem;
				}
			}
		}
	}

	@container (min-width: 1268px) {
		&.horizontal {
			&.multi {
				.search-results-container {
					justify-content: space-between;
				}

				.featured-providers-result {
					flex: calc(33.333% - (48px / 3));
					max-width: calc(33.333% - (48px / 3));

					&:nth-of-type(3n + 1) {
						margin-left: 0;
						margin-right: 12px;
					}
					&:nth-of-type(3n + 2) {
						margin-left: 12px;
						margin-right: 12px;
					}
					&:nth-of-type(3n + 3) {
						margin-left: 12px;
						margin-right: 0;
					}
				}
			}
		}
	}
}
